/**
 * Accordion for modules
 */

const dffAccordion = () => {
    const $ = jQuery.noConflict();
    $('.accordion .open-module .accordion-head').add('.single-course .accordion .accordion-head').on('click', function () {
        const $this = $(this);
        if (!$this.hasClass('active')) {
            $('.accordion-content').slideUp();
            $('.accordion-head').removeClass('active');
        }
        $this.toggleClass('active');
        $this.next().slideToggle();
    });
}

export default dffAccordion;
