import dffNextBackButtonsActive from '../scripts/next-back-buttons-active';
import dffAjaxLessons from '../scripts/ajax-lessons';

/**
 * The functionality for the
 * back button on the lesson.
 */
const $ = jQuery.noConflict();

$(document).on('click', '.lesson-header .back', '', function (e) {
    e.preventDefault();
    const moduleIndex = $(this).attr('module-index');
    const lessonIndex = $(this).attr('lesson-index');
    const lessonTestId = $(".course-sidebar").find(".accordion-item.module_" + moduleIndex + " .module-lesson-test").attr('lesson-test-id');
    const courseId = $(".modules-course").find(".course-sidebar").attr('course-id');
    const courseType = $(".modules-course").find(".course-sidebar").attr('type-course');
    const countLessonRow = $(".modules-course").find(".accordion").attr('count-lesson-row');
    $(".course-sidebar").find(".accordion-head.active").parent().addClass('active-btn');
    dffNextBackButtonsActive(moduleIndex, lessonIndex);
    dffAjaxLessons(moduleIndex, lessonIndex, lessonTestId, courseId, courseType, countLessonRow);
});

/**
 * The functionality for the
 * next button on the lesson.
 */
$(document).on('click', '.lesson-header .next', function (e) {
    e.preventDefault();
    const moduleIndex = $(this).attr('module-index');
    const lessonIndex = $(this).attr('lesson-index');
    const lessonTestId = $(".course-sidebar").find(".accordion-item.module_" + moduleIndex + " .module-lesson-test").attr('lesson-test-id');
    const courseId = $(".modules-course").find(".course-sidebar").attr('course-id');
    const courseType = $(".modules-course").find(".course-sidebar").attr('type-course');
    const countLessonRow = $(".modules-course").find(".accordion").attr('count-lesson-row');
    $(".course-sidebar").find(".accordion-head.active").parent().addClass('active-btn');
    dffNextBackButtonsActive(moduleIndex, lessonIndex);
    dffAjaxLessons(moduleIndex, lessonIndex, lessonTestId, courseId, courseType, countLessonRow)
}); 