import dffSliderToLesson from '../scripts/slider-lesson';
import dffGalleryFancybox from '../scripts/gallery-fancybox';

/**
 * Ajax switches between modules and
 * lessons on the course page in the my account
 */
const $ = jQuery.noConflict();
$(document).on('click', '.course-sidebar .accordion-content .tab-item', function (e) {
    e.preventDefault();

    const tabItem = $('.accordion-content ul li');
    const moduleIndex = $(this).attr('module-index');
    const lessonIndex = $(this).attr('lesson-index');
    const lessonTestId = $(".course-sidebar").find(".accordion-item.module_" + moduleIndex + " .module-lesson-test").attr('lesson-test-id');
    const courseId = $(".modules-course").find(".course-sidebar").attr('course-id');
    const courseType = $(".modules-course").find(".course-sidebar").attr('type-course');
    const countLessonRow = $(".modules-course").find(".accordion").attr('count-lesson-row');
    tabItem.removeClass('active');
    $(this).addClass('active');
    const closeModule = $('.accordion').find('.accordion-item.module_' + moduleIndex).next().hasClass('close-module');
    const dateOpen = $('.accordion').find('.accordion-item.module_' + moduleIndex).next().find('.accordion-head h6 span').text();
    const data = {
        action: "upload_lesson_ajax",
        module_index: moduleIndex,
        lesson_index: lessonIndex,
        course_id: courseId,
        lesson_test_id: lessonTestId,
        close_module: closeModule,
        date_open: dateOpen,
        course_type: courseType,
        count_lesson_row: countLessonRow
    };

    $.ajax({
        type: "POST",
        url: courses_ajax.url,
        data: data,
        beforeSend: function () {
            $('#loader').show();
        },
    }).done(function (response) {
        $(".main-content .content .lesson-container").html('<div class="lesson-wrapper">' + response + '</div>');
        dffSliderToLesson();
        dffGalleryFancybox();
        $(window.wp.mediaelement.initialize);
        $('#loader').hide();
    }).fail(function (response) {
        console.log(response);
    });
});